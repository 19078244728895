<template>
  <div style="position: relative">
    <b-sidebar
      id="sidebar-produto-fields"
      title="Informações visíveis"
      backdrop-variant="dark"
      backdrop
      right
      shadow
    >
      <div class="px-2 py-2">
        <div style="width: 100%" class="p-2">
          <h4 class="text-dark">Colunas customizadas</h4>
          <p>
            Selecione abaixo quais colunas deseja que estejam visíveis na
            listagem de produtos
          </p>
        </div>
        <div
          style="width: 100%; max-height: 100%; overflow-y: scroll"
          class="mb-2"
        >
          <div
            v-for="(el, index) in extraFields"
            :key="el.field"
            :label="el.label"
            class="mb-0 ml-5"
          >
            <div
              v-if="el.field !== 'actions'"
              style="display: flex; align-items: center; gap: 10px"
            >
              <span class="switch switch-icon">
                <label>
                  <input
                    type="checkbox"
                    @change="
                      (e) =>
                        handleSelectFieldToShow(
                          e.target.checked,
                          el.field,
                          index
                        )
                    "
                    :checked="isFieldShow(el.field)"
                  />
                  <span></span>
                </label>
              </span>
              <label :for="el.label" class="mb-0">{{ el.label }}</label>
            </div>
          </div>
        </div>
      </div>
      <template #footer="{ hide }">
        <div class="d-flex text-light align-items-center px-3 py-2">
          <button
            style="width: 100%"
            ref="salvarFields"
            class="btn btn-primary btn-hover-dark font-weight-bold"
            @click="handleSaveFields"
          >
            Salvar
          </button>
        </div>
      </template>
    </b-sidebar>

    <FilterFields
      :fields="[
        {
          type: 'number',
          label: 'Código',
          field: 'codigo',
        },
        {
          type: 'text',
          label: 'Descrição',
          field: 'descricao',
        },
        {
          type: 'text',
          label: 'Fornecedor',
          field: 'fornecedor',
        },
        {
          type: 'text',
          label: 'Grupo',
          field: 'id_grupo',
        },
        {
          type: 'number',
          label: 'Cod. Barras',
          field: 'cod_barras',
        },
        {
          type: 'text',
          label: 'Referência',
          field: 'referencia',
        },
        {
          type: 'number',
          label: 'N. Compra',
          field: 'numero_ultima_compra',
        },
        {
          type: 'number',
          label: 'Custo',
          field: 'custo',
        },
        {
          type: 'number',
          label: 'Preço',
          field: 'preco',
        },
        {
          type: 'text',
          label: 'Obs',
          field: 'obs',
        },
        {
          type: 'text',
          label: 'Descrição Comple.',
          field: 'desc_complementar',
        },
        {
          type: 'select',
          label: 'Tipo',
          field: 'tipo',
          options: [
            'Todos',
            'Mercadoria para Revenda',
            'Matéria-Prima',
            'Embalagem',
            'Produto em processo',
            'Produto Acabado',
            'Subproduto',
            'Material uso e consumo',
            'Ativo Imobilizado',
            'Outros',
          ],
        },
        {
          type: 'select',
          label: 'Status',
          field: 'status',
          options: ['Ativo', 'Inativo'],
        },
      ]"
    />

    <b-dropdown
      id="menu"
      variant="dark"
      v-b-tooltip.right="'Mais ações'"
      style="position: absolute; top: 0; left: 50px"
    >
      <template #button-content>
        <Menu :size="18" />
      </template>
      <b-dropdown-item
        v-b-modal.duplicarProduto
        :disabled="
          !actionsStore.selectedRows.length ||
          actionsStore.selectedRows.length > 1
        "
        >Duplicar produto</b-dropdown-item
      >
      <b-dropdown-item
        @click="
          () =>
            getRequestAdmin(() => {
              $bvModal.show('excluirProdutos');
            })
        "
        :disabled="!actionsStore.selectedRows.length"
        >Excluir produto(s)
      </b-dropdown-item>
      <b-dropdown-item
        @click="
          () =>
            getRequestAdmin(() => {
              $bvModal.show('reajustePreco');
            })
        "
        >Reajuste de Preço
      </b-dropdown-item>
    </b-dropdown>

    <b-button
      class="border border-muted"
      v-b-toggle.sidebar-produto-fields
      variant="secondary"
      v-b-tooltip.hover.right="'Customizar colunas'"
      style="position: absolute; right: 0; top: 0; z-index: 99"
    >
      <ToggleSwitchOutline :size="20" />
    </b-button>

    <b-button
      class="border border-muted"
      variant="secondary"
      v-b-tooltip.hover.right="'Terminal de consulta'"
      style="position: absolute; right: 50px; top: 0; z-index: 99"
    >
      <router-link
        target="_blank"
        :to="{
          name: 'terminalConsulta',
        }"
      >
        <BarcodeScan :size="20" />
      </router-link>
    </b-button>

    <b-modal
      id="excluirProdutos"
      title="Deseja excluir todos os produtos selecionados?"
      centered
      size="xs"
    >
      <div class="d-flex">
        <p>
          Somente será possível excluir um produto, se não houver nenhum tipo de
          movimentação para o produto.
        </p>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="excluirProduto"
            ref="excluirProdutos"
            class="mr-5"
          >
            Excluir
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="reajustePreco"
      title="Ajuste de preço com base no percentual (%) informado"
      centered
      size="xs"
    >
      <div>
        <b-col md="6">
          <b-form-group
            label="Informe o percentual do reajuste %"
            label-for="percentual"
          >
            <money
              class="form-control"
              v-model="percentual"
              v-bind="configMoney"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            id="pesado"
            label-for="pesado"
            label="Estoque Total"
            v-b-tooltip.hover.bottom="
              'Marcar para aplicar o reajuste para todo o estoque, caso marcar essa opção vai atualizar todo seu estoque'
            "
          >
            <span class="switch switch-icon">
              <label>
                <input
                  type="checkbox"
                  :value="false"
                  @change="setEstoqueTotal"
                  :checked="estoque_total == true"
                />
                <span></span>
              </label>
            </span>
          </b-form-group>
        </b-col>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="ajustaPreco"
            ref="reajustePreco"
            class="mr-5"
          >
            Ajustar preço(s)
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="duplicarProduto"
      title="Deseja duplicar o produto selecionado?"
      centered
      size="xs"
    >
      <label
        >Produto a ser duplicado:
        <p>{{ actionsStore.selectedRows[0]?.descricao }}</p>
      </label>
      <b-form-group label="Nova descrição" label-for="newDescriptionProduct">
        <b-form-input v-model="newProductDescription" />
      </b-form-group>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="duplicarProduto"
            ref="doubleProduct"
            class="mr-5"
          >
            Confirmar
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <TableApp
      titulo="Cadastro de Produtos"
      acaoBtn="/#/produtos/novo"
      textoBtn="Novo"
      api-url="/produtos/filter"
      :fields="fieldsToShow"
      classFilterColumn="produto"
      ref="produto"
      :perPage="10"
      :multiselect="true"
    >
      <template slot="customRow" scope="props">
        <span slot="codigo" v-if="props.field == 'codigo'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div>
                <a
                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >{{ props.rowData.codigo }}</a
                >
                <b-card-img
                  v-b-modal.view-product-imagem
                  :src="props.rowData.imagem"
                  class="rounded-0 ml-5"
                  style="width: 50px"
                  @click="
                    () => {
                      produtoToView = props.rowData.imagem;
                    }
                  "
                />
              </div>
            </div>
          </span>
        </span>
        <span slot="referencia" v-if="props.field == 'referencia'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div>
                <a
                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >{{ props.rowData.referencia }}</a
                >
              </div>
            </div>
          </span>
        </span>
        <span slot="descricao" v-if="props.field == 'descricao'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div>
                <a
                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >{{ props.rowData.descricao.substr(0, 50) }}</a
                >
                <span
                  class="text-muted font-weight-bold d-block"
                  v-if="props.rowData.desc_complementar"
                  >{{ props.rowData.desc_complementar }}</span
                >
              </div>
            </div>
          </span>
        </span>
        <span slot="fornecedor" v-if="props.field == 'fornecedor'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div class="font-weight-bold">
                {{ props.rowData.fornecedor }}
              </div>
            </div>
          </span>
        </span>
        <span slot="marca" v-if="props.field == 'marca'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div class="font-weight-bold">
                {{ props.rowData.marca }}
              </div>
            </div>
          </span>
        </span>
        <span slot="unidade_medida" v-if="props.field == 'unidade_medida'">
          <div class="font-weight-bold">
            {{ props.rowData.unidade_medida }}
          </div>
        </span>
        <span slot="custo" v-if="props.field == 'custo'">
          <div class="font-weight-bold">
            R$ {{ numberToReal(props.rowData.custo, "preco") }}
          </div>
        </span>
        <span slot="preco" v-if="props.field == 'preco'">
          <div class="font-weight-bold">
            R$ {{ numberToReal(props.rowData.preco, "preco") }}
          </div>
        </span>
        <span slot="quantidade" v-if="props.field == 'quantidade'">
          <div class="font-weight-bold">
            {{ numberToReal(props.rowData.quantidade, "quantidade") }}
          </div>
        </span>
        <span slot="preco_atacado" v-if="props.field == 'preco_atacado'">
          <div class="font-weight-bold">
            {{ numberToReal(props.rowData.preco_atacado, "preco_atacado") }}
          </div>
        </span>
        <span
          slot="quantidade_atacado"
          v-if="props.field == 'quantidade_atacado'"
        >
          <div class="font-weight-bold">
            {{
              numberToReal(
                props.rowData.quantidade_atacado,
                "quantidade_atacado"
              )
            }}
          </div>
        </span>
        <span slot="estoque_minimo" v-if="props.field == 'estoque_minimo'">
          <div class="font-weight-bold">
            {{ numberToReal(props.rowData.estoque_minimo, "estoque_minimo") }}
          </div>
        </span>
        <span slot="cod_barras" v-if="props.field == 'cod_barras'">
          <div class="font-weight-bold">
            {{ props.rowData.cod_barras }}
          </div>
        </span>
        <span slot="ncm" v-if="props.field == 'ncm'">
          <div class="font-weight-bold">
            {{ props.rowData.ncm }}
          </div>
        </span>
        <span
          class="pl-0 pt-8"
          slot="data_ultima_compra"
          v-if="props.field == 'data_ultima_compra'"
        >
          <div class="d-flex align-items-center">
            <div class="font-weight-bold">
              {{ formatDate(props.rowData.data_ultima_compra) }}
            </div>
          </div>
        </span>
        <span
          class="pl-0 pt-8"
          slot="data_ultima_venda"
          v-if="props.field == 'data_ultima_venda'"
        >
          <div class="d-flex align-items-center">
            <div class="font-weight-bold">
              {{ formatDate(props.rowData.data_ultima_venda) }}
            </div>
          </div>
        </span>
        <span slot="status" v-if="props.field == 'status'">
          <span
            v-if="props.rowData.ativo == true"
            class="label label-lg label-inline label-light-success"
            >Ativo</span
          >
          <span v-else class="label label-lg label-inline label-light-danger"
            >Inativo</span
          >
        </span>
        <span slot="actions" v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible; position: relative; width: 130px">
              <div class="dropdown dropdown-inline">
                <router-link
                  class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Editar'"
                  :to="{
                    name: 'produtosUpdate',
                    params: {
                      id_produto: props.rowData.id,
                    },
                  }"
                >
                  <Pencil :size="16" title="Editar" />
                </router-link>
                <button
                  @click="excluir(props.rowData)"
                  class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Excluir'"
                >
                  <TrashCanIcon :size="16" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>
    <b-modal id="view-product-imagem" centered title="Imagem do produto">
      <b-card-img :src="produtoToView" class="rounded-0"></b-card-img>
      <template #modal-footer="{ ok }">
        <div>
          <b-button @click="ok" variant="primary"> Ok </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import ToggleSwitchOutline from "vue-material-design-icons/ToggleSwitchOutline.vue";
import BarcodeScan from "vue-material-design-icons/BarcodeScan.vue";
import TableApp from "@/components/Table/Table2";
import { SistemaMixin } from "@/modules/Application";
import { onboardStore } from "../../../onboardStore";
import FilterFields from "@/components/FilterFields";
import Menu from "vue-material-design-icons/Menu";
import { actionsStore } from "@/components/Table/actionsStore";
import swal from "sweetalert";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
import _ from "lodash";

export default {
  name: "app-produto",
  mixins: [SistemaMixin],
  components: {
    Pencil,
    TrashCanIcon,
    TableApp,
    FilterFields,
    Menu,
    Money,
    ToggleSwitchOutline,
    BarcodeScan,
  },
  data() {
    return {
      actionsStore,
      onboardStore,
      newProductDescription: null,
      id_configuracao_lista: null,
      selectedFields: [],
      fieldsToShow: [],
      extraFields: [
        {
          index: 9,
          label: "Preço atacado",
          field: "preco_atacado",
          width: "8%",
          sortable: true,
          slot: true,
        },
        {
          index: 10,
          label: "Qtd. atacado",
          field: "quantidade_atacado",
          width: "8%",
          sortable: true,
          slot: true,
        },
        {
          index: 11,
          label: "Cód. de Barra",
          field: "cod_barras",
          width: "8%",
          sortable: false,
          slot: false,
        },
        {
          index: 12,
          label: "Marca",
          field: "marca",
          width: "8%",
          sortable: true,
          slot: true,
        },
        {
          index: 13,
          label: "NCM",
          field: "ncm",
          width: "8%",
          sortable: false,
          slot: true,
        },
        {
          index: 14,
          label: "Dt Última Compra",
          field: "data_ultima_compra",
          width: "8%",
          sortable: true,
          slot: true,
        },
        {
          index: 15,
          label: "Dt Última Venda",
          field: "data_ultima_venda",
          width: "8%",
          sortable: true,
          slot: true,
        },
        {
          index: 16,
          label: "Estoque mínimo",
          field: "estoque_minimo",
          width: "8%",
          sortable: true,
          slot: true,
        },
      ],
      produtoToView: null,
      fields: [
        {
          index: 0,
          label: "Código",
          field: "codigo",
          type: "text",
          slot: true,
          sortable: true,
          width: "5%",
          filterOptions: {
            enabled: false,
            placeholder: "Pesquisar codigo",
          },
        },
        {
          index: 1,
          label: "Referência",
          field: "referencia",
          type: "text",
          slot: true,
          sortable: true,
          width: "5%",
          filterOptions: {
            enabled: false,
            placeholder: "Pesquisar referência",
          },
        },
        {
          index: 2,
          label: "Descrição",
          field: "descricao",
          type: "text",
          slot: true,
          sortable: true,
          width: "30%",
          filterOptions: {
            enabled: false,
            placeholder: "Pesquisar descrição",
          },
        },
        {
          index: 3,
          label: "Fornecedor",
          field: "fornecedor",
          type: "text",
          slot: true,
          sortable: false,
          width: "15%",
          filterOptions: {
            enabled: false,
            placeholder: "Pesquisar nome",
          },
        },
        {
          index: 4,
          label: "UN",
          field: "unidade_medida",
          width: "5%",
          sortable: false,
          slot: true,
          filterOptions: {
            enabled: false,
          },
        },
        {
          index: 5,
          label: "Custo",
          field: "custo",
          width: "8%",
          sortable: true,
          slot: true,
          filterOptions: {
            enabled: false,
          },
        },
        {
          index: 6,
          label: "Preço",
          field: "preco",
          width: "8%",
          sortable: true,
          slot: true,
        },
        {
          index: 7,
          label: "Quantidade",
          field: "quantidade",
          width: "8%",
          sortable: true,
          slot: true,
        },

        {
          index: 98,
          label: "Status",
          field: "status",
          width: "2%",
          sortable: false,
          slot: true,
          filterOptions: {
            enabled: false,
          },
        },
        {
          index: 99,
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
          width: "15%",
          filterOptions: {
            enabled: false,
          },
        },
      ],
      percentual: 0,
      configMoney: settingMoney,
      estoque_total: false,
    };
  },
  async created() {
    this.fieldsToShow = _.cloneDeep(this.fields);
    this.selectedFields = _.cloneDeep(this.fields);
    try {
      const { data } = await this.$http.post("/configuracao-lista/find", {
        modulo: 1,
      });
      this.id_configuracao_lista = data?.id_configuracao_lista ?? null;
      if (data.lista.length > 0) {
        this.fieldsToShow = _.cloneDeep(data.lista);
        this.selectedFields = _.cloneDeep(data.lista);
      }
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    isFieldShow(field) {
      return !!this.fieldsToShow.find((el) => el.field === field);
    },
    handleSelectFieldToShow(value, field, index) {
      if (value) {
        const fieldToAdd = this.extraFields.find((el) => el.field === field);
        const aux = _.cloneDeep(this.selectedFields);
        aux.push(fieldToAdd);
        this.selectedFields = aux.sort((a, b) =>
          a?.index < b?.index ? -1 : 1
        );
      } else
        this.selectedFields = this.selectedFields
          .filter((el) => el.field !== field)
          .sort((a, b) => (a.index < b.index ? -1 : 1));
    },
    async handleSaveFields() {
      this.addLoadingToButton("salvarFields");
      try {
        const { data } = await this.$http.post(
          this.id_configuracao_lista
            ? "/configuracao-lista/update"
            : "/configuracao-lista/create",
          {
            ...(this.id_configuracao_lista && {
              id_configuracao_lista: this.id_configuracao_lista,
            }),
            modulo: 1,
            lista: this.selectedFields,
          }
        );
        this.$toast.success(data.msg);
        this.fieldsToShow = [...this.selectedFields];
        if (!this.id_configuracao_lista)
          this.id_configuracao_lista = data.data.id_configuracao_lista;
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("salvarFields");
      this.$root.$emit("bv::toggle::collapse", "sidebar-produto-fields");
    },
    async duplicarProduto() {
      const id_produto = this.actionsStore?.selectedRows[0]?.id;
      this.addLoadingToButton("doubleProduct");
      if (!id_produto) {
        this.$toast.error("Produto sem id válido!");
        this.removeLoadingToButton("doubleProduct");
        return;
      }
      if (!this.newProductDescription) {
        this.$toast.error("Nova descrição obrigatória!");
        this.removeLoadingToButton("doubleProduct");
        return;
      }
      try {
        await this.$http.post("/produtos/duplicar", {
          id_produto,
          descricao: this.newProductDescription,
        });
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Produto duplicado com sucesso!");
        this.$refs.produto.refresh();
        this.newProductDescription = null;
        this.$bvModal.hide("duplicarProduto");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("doubleProduct");
    },
    async excluirProduto() {
      this.addLoadingToButton("excluirProdutos");
      try {
        await this.$http
          .post("/produtos/delete-all", {
            produtos: this.actionsStore.selectedRows.map((produto) => ({
              id: produto.id,
            })),
          })
          .then((result) => {
            this.actionsStore.selectedRows = [];
            this.actionsStore.updateTable = !this.actionsStore.updateTable;
            this.$toast.success("Solicitação realizada com sucesso!");
            this.$refs.produto.refresh();
            this.$bvModal.hide("menuAcoes");
            this.$bvModal.hide("excluirProdutos");
            swal.stopLoading();
            swal.close();
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
        this.actionsStore.selectedRows = [];
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$bvModal.hide("menuAcoes");
        this.$bvModal.hide("excluirProdutos");
      } catch (error) {
        this.$bvModal.hide("excluirProdutos");
      }
      this.removeLoadingToButton("excluirProdutos");
    },
    excluir(data) {
      this.getRequestAdmin(() => {
        this.$swal
          .fire({
            icon: "warning",
            title: "Excluir o Produto " + data.descricao + "?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/produtos/delete", {
                id: data.id,
              });
              this.$refs.produto.refresh();
              this.$toast.success("Produto excluído!");
            }
          });
      });
    },
    setEstoqueTotal() {
      this.estoque_total = this.estoque_total == true ? false : true;
    },
    async ajustaPreco() {
      this.addLoadingToButton("reajustePreco");
      if (!this.percentual) {
        this.$toast.error("Informe o percentual!");
        this.removeLoadingToButton("reajustePreco");
        return;
      }
      try {
        await this.$http.post("/produtos/ajuste-preco", {
          produtos: this.actionsStore.selectedRows.map((produto) => ({
            id_produto: produto.id,
          })),
          estoque_total: this.estoque_total,
          percentual: this.percentual,
        });
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Ajuste aplicado com sucesso!");
        this.$refs.produto.refresh();
        this.percentual = 0;
        this.estoque_total = false;
        this.actionsStore.selectedRows = [];
        this.$bvModal.hide("reajustePreco");
      } catch (error) {
        this.percentual = 0;
        this.estoque_total = false;
        console.log(error);
      }
      this.removeLoadingToButton("reajustePreco");
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    if (this.onboardStore.currentStep === 7) {
      this.fireOnboardToast({
        id: "produtosLista",
        title: "4/6 - Produto cadastrado!",
        content:
          "No próximo passo precisamos cadastrar pelo menos um cliente para fazer uma venda. Deseja fazer isso agora?",
        buttonText: "Sim",
        nextRouterStep: "pessoaNova",
        fowardStep: () => (this.onboardStore.currentStep = 6),
      });
    }
  },
};
</script>
