<template>
  <div class="topbar">
    <b-dropdown size="sm" variant="link" toggle-class="topbar-item text-decoration-none" no-caret right no-flip>
      <template v-slot:button-content>
        <div class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 pulse pulse-primary position-relative" >
          <span class="svg-icon svg-icon-xl">
            <inline-svg src="media/svg/icons/Code/Info-circle.svg" />
          </span>
          <span class="pulse-ring"  v-if="notificationStore.totalCentral > 0" />
          <span class="font-size-sm position-absolute notifications" v-if="notificationStore.totalCentral > 0">{{ notificationStore.totalCentral }}</span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <form>
          <KTDropdownNotificationCentral></KTDropdownNotificationCentral>
        </form>
      </b-dropdown-text>
    </b-dropdown>
    <b-dropdown size="sm" variant="link" toggle-class="topbar-item text-decoration-none" no-caret right no-flip>
      <template v-slot:button-content>
        <div class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 pulse pulse-primary position-relative" >
          <span class="svg-icon svg-icon-xl">
            <inline-svg src="media/svg/icons/Code/Compiling.svg" />
          </span>
          <span class="pulse-ring"  v-if="notificationStore.total > 0" />
          <span class="font-size-sm position-absolute notifications" v-if="notificationStore.total > 0">{{ notificationStore.total }}</span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <form>
          <KTDropdownNotification></KTDropdownNotification>
        </form>
      </b-dropdown-text>
    </b-dropdown>

    <KTQuickUser></KTQuickUser>
  </div>
</template>

<style lang="scss">
.notifications {
  right: 5px;
  bottom: 0;
}
.topbar {
  .dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
import KTDropdownNotificationCentral from "@/view/layout/extras/dropdown/DropdownNotificationCentral.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import { notificationStore } from "../../../notificationStore";

export default {
  name: "KTTopbar",
  data() {
    return { notificationStore };
  },
  components: {
    KTDropdownNotification,
    KTQuickUser,
    KTDropdownNotificationCentral,
  },
  methods: {},
  computed: {},
};
</script>
